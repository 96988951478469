import axios from "axios"
import { useContext } from "react";
import AuthContext from "../providers/AuthContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const DeleteButton = ({ path, returnPath }) => {
    const { cookies } = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();


    const handleClick = async e => {
        Swal.fire({
            title: "Czy na pewno?",
            text: "Czy na pewno usunąć?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Tak",
            cancelButtonText: "Nie",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(API_URL + path, {
                    headers: {
                        Authorization: 'Bearer ' + cookies.access_token
                    },
                })
                    .then(val => {
                        navigate(returnPath);
                    })
                    .catch(err => {
                        Swal.fire({
                            icon: "error",
                            title: "Ups",
                            text: "Coś poszło nie tak, więcej informacji w konsoli!",
                        });
                        console.log(err);
                    });
            }
        });

    }

    return (
        <button type="button" onClick={handleClick} className="flex w-full justify-center rounded-md bg-red-600 hover:bg-red-500 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Usuń</button>
    )
}

export default DeleteButton;