import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Input from "../../components/Input";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../providers/AuthContext";
import SaveButton from "../../components/SaveButton";
import BackButton from "../../components/BackButton";
import CheckboxInput from "../../components/CheckboxInput";
import DateInput from "../../components/DateInput";
import TextArea from "../../components/TextArea";
import Select from "../../components/Select";
import DeleteButton from "../../components/DeleteButton";

const CalendarForm = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const { cookies } = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const isNewRecord = !(id > 0);
    const navigate = useNavigate();
    const [date, setDate] = useState(searchParams.get('date'));
    const [title, setTitle] = useState('');
    const [icon, setIcon] = useState('');
    const [description, setDescription] = useState('');
    const [dayOff, setDayOff] = useState(false);
    const [link, setLink] = useState('');

    const getEvent = useCallback(async () => {
        axios.get(API_URL + '/calendar/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.access_token
            }
        }).then(response => {
            const body = response.data;
            setDate(body.date);
            setTitle(body.title);
            setIcon(body.icon);
            setDescription(body.description);
            setDayOff(body.dayOff);
            setLink(body.link);
        })
            .catch(e => {
                console.log(e);
            })
    }, [API_URL, cookies.access_token, id]);

    const saveEvent = async e => {
        e.preventDefault();
        if (isNewRecord) {
            axios.post(API_URL + '/calendar', JSON.stringify({
                date,
                title,
                description,
                dayOff,
                iconId: icon.id,
                link,
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
                .then(response => {
                    console.log(response);
                    if (response.status === 201 || response.status === 200) {
                        navigate(`/calendar?date=${date}`);
                    }
                })
                .catch(e => {
                    console.log(e);
                    //   setErrMsg(e.response.data.error);
                });
        } else {
            axios.put(API_URL + '/calendar/' + id, JSON.stringify({
                date,
                title,
                description,
                dayOff,
                iconId: icon.id,
                link,
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
                .then(response => {
                    console.log(response);
                    if (response.status === 201 || response.status === 200) {
                        navigate(`/calendar?date=${date}`);
                    }
                })
                .catch(e => {
                    console.log(e);
                    alert(e);
                    //   setErrMsg(e.response.data.error);
                });
        }
    }

    useEffect(() => {
        if (!isNewRecord) {
            getEvent();
        }
    }, [isNewRecord, getEvent]);

    return (
        <section className="items-center grid md:grid-cols-5" style={{ height: '100vh' }}>
            <div></div>
            <form onSubmit={saveEvent} className="bg-white rounded-md p-12 col-span-3">
                <h2 className="text-black">
                    <strong>
                        {isNewRecord ? 'Nowy event' : 'Aktualizacja eventu nr ' + id}
                    </strong>
                </h2>
                <div className="grid grid-cols-2">
                    <DateInput isRequired={true} data={date} updateData={setDate} name='date' label='Data' />
                    <Input isRequired={true} data={title} updateData={setTitle} name='title' label='tytuł' />
                    <Input data={link} updateData={setLink} name='link' label='Link' />
                    <Select isRequired={true} data={icon} updateData={setIcon} name='icon' label='Ikona' endpoint='/calendar-icon/all' />
                </div>
                <TextArea data={description} updateData={setDescription} name='description' label='Opis' />
                <CheckboxInput data={dayOff} updateData={setDayOff} name='dayOff' label='Czy dzień wolny?' />
                <div className="py-5 flex flex-row col-gap-4">
                    <SaveButton />
                    <BackButton returnPath={`/calendar?date=${date}`} />
                    {!isNewRecord && <DeleteButton path={`/calendar/${id}`} returnPath={`/calendar?date=${date}`} />}
                </div>
            </form>
        </section>
    );
}

export default CalendarForm;